.renewals-page {
  position: relative;

  &__mobile-header-line {
    width: 100%;
    height: 1px;
    margin: 2.6em 0 0 0;
    background-color: $cwColor-black-light;
    display: none;

    @media only screen and (max-width: $mobile) {
      display: block;
    }
  }

  &__row {
    @extend .row-group, .row-group--white;
    margin: 0.8em 0;
    padding: 0.8em 0;

    &.row-total--disabled {
      .input-group {
        position: relative;
        height: 38px;
        color: $cwColor-text-disabled;
        &::before {
          font-size: 15px;
          position: absolute;
          left: 83px;
          top: 9px;
          content: "%";

          @media only screen and (max-width: $mobile) {
            position: absolute;
            left: auto;
            right: 8px;
            top: 10px;
            pointer-events: none;
          }
        }
      }
    }

    &--new-info {
      background: $cwColor-blue-lighter;

      @media only screen and (max-width: $mobile) {
        text-align: center;
      }

      .white-background {
        background-color: $cwColor-white;
      }
    }

    .radio-group__info {
      float: inherit;
    }

    .input-group--inline {
      padding: 0.2em 0 0 2.5em;
      width: 400px;

      textarea {
        width: 100%;
        background-color: $cwColor-white;
        margin-left: -11px;

        @media only screen and (max-width: $mobile) {
          margin-left: 0;
        }
      }

      @media only screen and (max-width: $mobile) {
        padding: 0;
      }
    }

    .radio-group__icon-group {
      font-size: 14px;
      padding: 0.2em 0 0 2em;

      @media only screen and (max-width: $mobile) {
        padding: 0;
      }

      li {
        list-style: none;
      }

      ul li,
      p {
        padding-bottom: 0.9em;
        margin: 0;
        &::before {
          content: "·";
          margin-right: 0.4em;
        }

        @media only screen and (max-width: $mobile) {
          text-align: center;
        }
      }
    }
  }

  &_btn {
    &--top {
      top: 0;
      right: 0;
      position: absolute;
    }

    &--bottom {
      margin: 1em 0 5em 0;

      @media only screen and (max-width: $mobile) {
        margin: 1em 0 0 0;
        position: relative;
        width: 100%;
      }
    }
  }

  &__confirmation {
    background-color: $cwColor-base-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
    padding: 1em 1em 7em;
    margin-top: 2em;

    &--icon {
      fill: $cwColor-black-medium;
      background-color: $cwColor-black-mlight;
      border-radius: 50%;
      padding: 1em;
      margin-bottom: 1em;
    }

    &--subtitle {
      font-size: 1em;
    }

    &--contact {
      font-size: 0.8em;
    }
  }

  &__form {
    padding-top: 2em;
  }

  h4 {
    margin-bottom: 0;
  }

  .steps-line {
    margin-top: 2em;
  }

  .page-title {
    padding: 0;
    color: $cwColor-black-xmedium;
    font-size: 0.85em;
    text-align: left;

    @media only screen and (max-width: $tablet){
      &__first {
        width: 65%;
      }
    }

    @media only screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  @media only screen and (max-width: $mobile) {
    h4,
    .page-title {
      text-align: center;
    }

    button.renewals-page_btn--top,
    button[disabled].renewals-page_btn--top {
      top: -3em;
      font-size: 0.8em;
      background-color: transparent;
      border: 0;
      color: $cwColor-blue-selected;
    }
  }
}
