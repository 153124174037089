nav {
  max-width: $sidebar-width;
  min-width: $sidebar-width;
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  position: relative;
  left: 0;
  padding: 20px 10px 0 10px;
  z-index: 999;

  @media only screen and (max-width: $mobile) {
    max-width: none;
    padding-top: 0;
  }

  .lifecycle {
    border-top: 1px dotted $cwColor-black-light;
    padding-top: 4px;
    flex-grow: 1;

    &--without-contact-info {
      margin-top: 5px;
    }

    &--simplified {
      margin-top: 68px;
    }

    h5 {
      font-size: 16px;
      margin-bottom: 3px;
      text-align: center;
      color: $cwColor-base-bluegray;
    }

    @media only screen and (max-width: $mobile) {
      display: none;
    }

    ul.list {
      padding: 0;
      margin-top: 10px;

      @media only screen and (max-width: $mobile) {
        width: 200px;
        overflow: hidden;
        margin: 0 auto;
      }

      .item {
        height: 40px;

        @for $i from 1 to 10 {
          &:nth-child(#{$i}) .circle {
            animation-delay: $i * 0.02s;
          }
        }

        .indicator {
          width: 50px;
          height: 40px;
          position: relative;
          float: left;

          .semiline {
            width: 3px;
            height: 50%;
            margin: 0 auto;
            background: $cwColor-black-light;
          }

          .circle {
            width: 30px;
            height: 30px;
            position: absolute;
            border-radius: 50%;
            border: 3px solid $cwColor-black-light;
            top: 0;
            left: 10px;
            background: $cwColor-black-xlight;
          }
        }

        span {
          line-height: 32px;
          font-size: 14px;
          text-align: left;
          color: $cwColor-black-dark;
          margin-bottom: 15px;
        }

        &.start {
          .semiline:first-child {
            background: inherit;
          }
        }

        &.end {
          min-height: 40px;
          height: auto;

          .semiline:nth-child(2) {
            background: inherit;
          }
        }

        &.complete {
          .circle {
            background: $cwColor-base-blue;
            border-color: $cwColor-base-blue;
          }
        }

        &.current {
          .semiline:first-child {
            background: $cwColor-base-blue;
          }

          .circle {
            background: $cwColor-base-orange;
            border-color: $cwColor-base-orange;
          }
        }

        &.disable {
          .circle {
            background: $cwColor-black-xlight;
            border-color: $cwColor-black-light;
          }
        }
      }
    }
  }
}

.agent-nav-box {
  padding: 18px 0 14px;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;

  @media only screen and (max-width: $mobile) {
    padding-top: 0;
    width: 200px;
    margin: 0 auto;
  }

  &__avatar-container {
    width: 65px;
    height: 65px;
    margin: 0 auto;

    img {
      width: 100%;
      border-radius: 50%;
    }

    &--triple-photo {
      width: 143px;
      height: 60px;

      img {
        -webkit-border-radius: initial;
        -moz-border-radius: initial;
        -o-border-radius: initial;
        border-radius: initial;
        border: initial;
      }
    }
  }

  &__title {
    margin: 5px 0 15px;

    &-description {
      list-style: none;
      margin: -2px 0 0 0;
      padding: 0;
      display: inline-block;
      width: 131px;

      li {
        font-size: 12px;
      }
    }
  }

  &__title-value {
    font-size: 15px;
    font-weight: 100;
    margin: 0;
    line-height: 22px;
    color: $cwColor-base-bluegray;
  }

  &__body-info {
    line-height: 20px;

    a {
      color: $cwColor-base-blue;
      margin-top: 3px;
      display: block;
      font-size: 15px;
      font-weight: lighter;

      &:hover {
        color: $cwColor-black-dark;
      }
    }

    &-item {
      font-size: 12px;
      margin: 4px 0;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 161px;
    margin: 8px auto 0;

    &--contact {
      padding: 16px 0 0 0;
    }
  }

  &__action-item {
    color: $cwColor-base-blue;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    width: 100%;
    text-align: center;

    &:before {
      font-size: 32px;
      position: absolute;
      bottom: 21px;
      left: 11px;
    }

    & > svg {
      margin-bottom: 4px;
    }

    &--contact {
      font-size: 12px;
      width: 100%;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:before {
        font-size: 20px;
        position: static;
        margin-right: 10px;
      }
    }
  }

  &__action-item--modified {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &::before {
      position: static;
    }
  }
}

.agent-nav-box.agent-nav-box--custom {
  padding-top: 0;
  margin-top: 0;

  .agent-nav-box__avatar-container {
    max-width: 190px;
    width: auto;
    height: auto;

    img {
      border-radius: 0;
    }
  }

  .agency-logo {
    width: auto;
    max-width: 100%;
  }

  .agent-nav-box__body-info {
    padding-top: 5px;
  }

  @media only screen and (max-width: $mobile) {
    margin: 0 auto;
    border: none;
  }
}

.agent-nav-box.agent-nav-box--without-chat {
  .agent-nav-box__actions {
    width: 100px;
  }
}

.agent-nav-box.agent-nav-box--triple-photo {
  padding-top: 0;
  margin-top: 0;
}

.agent-nav-box.agent-nav-box--subagent {
  margin-top: 0;
}

.agent-nav-box.agent-nav-box--custom_triple-photo {
  margin-top: 46px;
  padding: 18px 0 14px;

  @media only screen and (max-width: $mobile) {
    padding-top: 0;
    margin-top: 0;
  }

  .agent-nav-box__avatar-container--triple-photo {
    width: 116px;
    height: 48px;
    img {
      border-radius: none;
    }
  }
}
