.recommend-coverage-modal {
  .modal-layout__main {
    @media only screen and (max-width: $mobile) {
      padding-bottom: 0;
      margin-top: 30px;
      text-align: center;
    }

    .modal-layout__buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: $mobile) {
        display: block;
        padding-bottom: 0;
        margin-top: 50%;
        text-align: center;
        button {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  .modal-layout__sidebar {
    @media only screen and (max-width: $mobile) {
      padding: 12px 0 40px 0;
    }
  }

  .modal-layout__footer {
    padding: 0 16px;

    &:before {
      top: -5px;
    }
  }

  .recommend-coverage-modal-info:first-child {
    padding-bottom: 25px;
  }

  .recommend-coverage-modal-info--title {
    font-weight: bold;
  }

  .recommend-coverage-modal-info--help-text {
    font-size: 14px;
  }

  &-footer {
    &__list {
      display: flex;
      justify-content: space-evenly;
      @media only screen and (max-width: $mobile) {
        display: block;
      }
    }

    &__item {
      display: inline-block;
      float: left;
      width: 25%;
      height: 120px;
      text-align: center;
      color: $cwColor-black-xmedium;

      &--label {
        font-size: 13px;
      }

      @media only screen and (max-width: $mobile) {
        width: 50%;
        font-size: 50px;
      }
    }

    &__icon {
      font-size: 60px;
    }

    &__icon:before {
      color: $cwColor-black-xmedium;
    }
  }
}

.recommended-coverage-additional-info {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  font-weight: lighter;

  div {
    min-width: 0;

    &.checked {
      color: black;

      & path {
        fill: black;
      }
    }
  }

  span {
    display: block;
    margin: 2px 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.recommend-coverage-page {
  .insurance-block {
    @media only screen and (max-width: $tablet){
      text-align: center;
    }
  }

  .page-title {
    padding: 0 0 10px 0;
  }

  .row-coverage {
    @extend .row-group, .row-group--white;
    position: relative;

    .input-group--inline {
      padding-left: 5px;
    }
  }

  .recommend-coverage-options {
    width: 100%;

    &.upsell {
      .icons-radio-group {
        display: flex;
        justify-content: space-evenly;

        label {
          margin: 2px 12px;
        }

        &:after {
          content: unset;
        }
      }
    }
  }

  .recommend-coverage-label {
    text-align: left;
    font-size: 17px;
  }

  .radio-group__icon-group {
    width: 35%;

    @media only screen and (max-width: $tablet){
      width: 40%;
    }

    @media only screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .label__help-info {
    line-height: 18px;
    font-size: 14px;
    padding-top: 8px;

    &.upsell {
      font-weight: lighter;
      color: black;
    }
  }

  .more-info-button {
    @extend .link;
    display: inline-block;
    float: left;
    min-width: 120px;
    font-size: 14px;
    text-align: left;

    &.btn--disabled {
      @extend .btn, .btn[disabled];
    }

    &--mobile-link {
      color: $cwColor-base-blue;
      background-color: transparent;
      border: none;
      padding: 0;
      text-align: left;

      &:hover {
        background-color: transparent;
        border: none;
        color: $cwColor-black-dark;
      }

      &:focus {
        outline: none;
      }

      &.btn--disabled {
        font-size: 14px;
        color: $cwColor-black-medium;
        background-color: transparent;
        border: none;
        padding: 0;

        &:hover {
          background-color: transparent;
          border: none;
          color: $cwColor-black-dark;
        }
      }
    }
  }

  .cw-flash-notification__title {
    font-size: 14px;
    text-align: left;
  }
}
