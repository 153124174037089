.electronic-delivery-modal {
  padding: 5px 25px;
  max-width: 600px;
  max-height: 300px;

  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin: 20px 0 30px;
  }

  &__content {
    padding-bottom: 30px;
  }

  p {
    margin-bottom: 15px;
  }
}
