.elg-form-page {
  .page-title {
    padding: 0;

    &+ .page-description {
      width: 77%;

      @media only screen and (max-width: $tablet){
        width: 100%;
      }
    }
  }

  &__row {
    position: relative;
    background-color: $cwColor-white;
    width: 100%;
    margin: 3px 0;
  }
}

.elg-list-page {

  &__table {
    display: table;
    width: 100%;
    margin: 24px 0 18px 0;
    padding: 16px 16px 0;
    background-color: $cwColor-white;
  }

  &__heading {
    display: table-header-group;
    font-weight: bold;
  }

  &__body {
    display: table-row-group;
  }

  &__row {
    display: table-row;
  }

  &__cell {
    border-bottom: 1px solid #F0F0F0;
    display: table-cell;
    padding: 4px 8px 8px 0;
    font-size: 0.875rem;
  }

  &__actions-cell-container {
    display: flex;
  }

  &__actions {
    background-color: $cwColor-white;
    padding: 16px;

    a {
      display: flex;
      justify-content: center;
      width: 180px;
      font-size: 16px;
      font-weight: 400;
      align-items: center;

      @media only screen and (max-width: $mobile) {
        width: 100%;
        margin-top: 8px;
      }
    }
  }

  &__mobile-element {
    background-color: $cwColor-white;
    padding: 16px;
    margin-bottom: 8px;
  }

  &__mobile-row {
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-child {
      margin: 0;
    }
  }

  &__mobile-header {
    font-weight: bold;
  }

  h6 {
    font-size: 1.125rem;
  }

  &__new-elg {
    font-size: 1rem;

    &:hover {
      color: $cwColor-white;

      .elg-list-page__new-elg-icon::before {
        color: $cwColor-white;
      }
    }
  }

  &__new-elg-icon {
    svg {
      fill: #1e657a;
      margin-right: 4px;
    }
  }

  &__error-message {
    color: $cwColor-base-red;
    text-align: center;
    margin-bottom: 18px;
    font-size: 0.875rem;
  }
}

.elg-list-page__body {
  .elg-list-page__row:last-child {
    .elg-list-page__cell {
      border-bottom: 0;
    }
  }
}
