.form-group.form-group--error .form-select2 .Select-control {
  border-color: #f00;
}

.form-select2 {
  width: 100%;
  .Select-control,
  .Select-menu-outer {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
  }
  .Select-control {
    background: transparent;
    border-radius: 0;
    height: 65px;
    .Select-placeholder {
      max-width: 90%;
    }
  }
  &.form-select2--long {
    .Select-control {
      height: 38px;
    }
  }
  &.is-open {
    .Select-control {
      background: #fff;
    }
  }
  &.is-disabled {
    .Select-control {
      background: transparent;
      .Select-placeholder {
        visibility: hidden;
      }
    }
  }
}
