.page {
  @media only screen and (max-width: $tablet){
    .page-wrapper {
      padding: 0 5px;
    }

    h3 {
      text-align: center;
      margin: 5px 0 10px 0;
      font-size: 30px;
    }
  }

  &__name {
    margin-bottom: 0;
     @media only screen and (max-width: $tablet){
      text-align: center;
    }
  }

  &__title {
    margin-top: 0;
    padding: 0 0 16px 0;

    &--small-margin {
      padding-bottom: 0;
      margin-bottom: 5px;
    }
  }

  &__description {
    margin: 0 0 12px;
    font-size: 14px;
    line-height: 20px;
  }
}

h4.page-title {
  font-size: 24px;
  font-weight: 100;
  line-height: 32px;
  margin: 10px 0;
}

// DEPRECATED
.page-title {
  width: 62%;
  margin-bottom: 7px;
  color: $cwColor-base-bluegray;
  padding: 10px 0 16px 0;

  @media only screen and (max-width: $tablet){
    display: inline-block;
    width: 100%;
    text-align: center;

    .page-wrapper {
      padding: 0 5px;
    }
  }

  @media only screen and (max-width: $mobile) {
    text-align: center;
    margin-top: 1px;
    margin-bottom: 7px;
    padding-top: 0;
    padding-bottom: 0;

    &__main {
      display: block;
    }
  }

  &+ .page-description {
    margin-top: -4px;
  }
}

// DEPRECATED
.page-description {
  width: 60%;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 20px;

  @media only screen and (max-width: $mobile) {
    padding: 0 10px;
  }

  @media only screen and (max-width: $tablet){
    width: 100%;
    text-align: center;
    padding-left: 10px;

    &__with_cta {
      display: inline-block;
    }
  }

  &__hidden {
    @media only screen and (max-width: $tablet){
      display: none;
    }
  }

  &+ span {
    display: block;
    font-size: 0;
  }
}

// DEPRECATED
h5.light {
  @media only screen and (max-width: $mobile) {
    width: 100%;
    text-align: center;
  }
}
