@import '../dynamicTooltip' ;
.company-data-page {

    &__label {
      padding-left: 32px !important;
    }

    h5 {
        display: inline-block;
        margin: 7px 0 3px 0;
    }

    h5.company-subtitle {
      @media only screen and (max-width: $mobile) {
        display: none;
      }
    }

    .row-company {
      @extend .row-group, .row-group--white;
      padding: 0;

      &--map {
        overflow: hidden;
      }

      &__loader-container {
        height: 200px;
      }

      &__empty-results {
        display: inline-block;
        padding: 0 12px 15px;
        font-size: 14px;
        line-height: 16px;
        color: $cwColor-black-xmedium;

        @media only screen and (max-width: $mobile) {
          width: 100%;
          margin: 6px 0 10px;
          text-align: center;
        }
      }
    }

    .annual-revenue {
      label span {
        .inline-block {
          margin-left: 4px;
          }
      }
    }

    .street-address .input-group .form-input {
      width: 400px;

      @media only screen and (max-width: $tablet){
        width: 100%;
      }
    }

    .address-floor .input-group .form-input,
    .store-number .input-group .form-input,
    .zip-code .input-group .form-input {
      width: 160px;

      @media only screen and (max-width: $mobile) {
        width: 100%;
      }
    }

  &__business-detail {
    @include dynamic-tooltip($larger-box-tooltip-position: 690px);
  }

  &__business-name {
    .company-name-row {
      .form-group {
        padding: 0;

        .input-group {
          display: block;

          input {
            font-size: 16px;
            border: 1px solid #438bd7;
            height: 50px;
            padding: 6px 11px;

            &::-webkit-input-placeholder {
              font-size: 1em;
              font-weight: 400;
              color: $cwColor-black-xmedium;
            }

            &:-moz-placeholder {
              font-size: 1em;
              font-weight: 400;
              color: $cwColor-black-xmedium;
            }

            &::-moz-placeholder {
              font-size: 1em;
              font-weight: 400;
              color: $cwColor-black-xmedium;
            }

            &:-ms-input-placeholder {
              font-size: 1em;
              font-weight: 400;
              color: $cwColor-black-xmedium;
            }
          }
        }
      }
    }
  }
}
