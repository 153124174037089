.practice-areas-page {
  .page-title {
    padding: 0;

    &+ .page-description {
      width: 77%;

      @media only screen and (max-width: $tablet){
        width: 100%;
      }
    }
  }

  &__row {
    position: relative;
    margin-bottom: 0;
    @extend .row-group, .row-group--white;

    .oc-select__input-container {
      width: 290px;
    }

    @media only screen and (max-width: $mobile) {
      .oc-icon-option-container {
        width: 48%;
      }
    }
  }
}
