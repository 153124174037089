.step-switcher {
  .continue-button,
  .continue-button.btn--disabled {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  &--bottom {
    width: 100%;
    padding-top: 14px;

    .continue-button,
    .continue-button.btn--disabled {
      justify-content: center;
    }
  }

  font-size: 14px;
  
  @include qf-clearfix();

  @media only screen and (max-width: $tablet){
    width: 100%;
    margin: 0;
    padding: 5px 0 0;


  }

  @media only screen and (max-width: $mobile) {
    position: relative;
    padding: 0 10px 9px 10px;
    border-bottom: 1px solid $cwColor-black-light;

    .mobile-hidden {
      display: none;
    }

    .back-mobile:before {
      color: $cwColor-base-blue;
      vertical-align: middle;
      line-height: 5px;
    }

    &--bottom {
      position: relative;
      margin-top: 17px;
      border-bottom: none;

      .continue-mobile:after,
      .back-mobile:before {
        display: none;
      }

      .continue-mobile .mobile-hidden,
      .back-mobile .mobile-hidden {
        display: inline-block;
      }
    }
  }

  &--top {
    position: absolute;
    width: calc(100% - 20px);
    top: 16px;

    .continue-button,
    .continue-button.btn--disabled {
      justify-content: center;
    }

    @media screen and (max-width: $break-large) {
      position: relative;
      top: auto;
      width: auto;
    }

    @media only screen and (max-width: $mobile) {
      padding: 0 10px;

      .continue-button,
      .continue-button.btn--disabled {
        justify-content: end;
      }

      .continue-button--mobile-link {
        line-height: normal;
        height: auto;
        font-weight: normal;
      }
    }
  }
}

.agent-body--c4a {
  .step-switcher--top {
    @media only screen and (max-width: $mobile) {
      padding-top: 20px;
    }
  }
}

.row-submit {
  &--bottom {
    padding: 0 !important;
    margin: 0;

    @media only screen and (max-width: $tablet){
      text-align: center;
    }

    .help-info {
      float: left;
      margin-top: 15px;
      font-size: 13px;
      color: $cwColor-black-dark;

      @media only screen and (max-width: $mobile) {
        width: 100%;
      }

      a {
        color: $cwColor-base-blue;
      }
    }

    .btn.right,
    .back-link {
      display: none;
    }
  }

  .btn {
    margin-top: 0;
  }
}
