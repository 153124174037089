div.form-group.form-group__checkbox {
  padding-left: 230px;
  max-width: 775px;

  .form-group__checkbox-container {
    display: flex;
  }

  .terms__container {
    display: flex;
    flex-direction: column;
  }

  .terms__text {
    font-size: 0.875em;
    margin-bottom: 20px;
  }

  .terms__footer {
    color: $cwColor-text-disabled;
    font-size: 0.875em;
  }

  @media only screen and (max-width: $mobile) {
    padding: 5px 10px;
  }
}

