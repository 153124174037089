//header
$header-height: 70px;

//navbar variables
$navbar-padding: 0 25px;

$sidebar-width: 240px;

$helpPanel-width: 220px;

$break-large: 1200px;

$defaultTextColor: #505050;

$box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);

$mobile: 767px;

$tablet: 1200px; 