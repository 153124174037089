.popup--other {
  .popup__content {
    text-align: center;

    .popup__content__left {
      text-align: left;
    }
  }
}

.popup--requireLocation {
  padding: 32px 48px;

  @media only screen and (max-width: $mobile) {
    padding: 35px 18px;
  }

  .btn-close {
    height: 40px;
    width: 40px;
  }

  .title {
    margin: 0 0 16px 0;
    text-align: center;
  }

  .description {
    font-size: 0.875em;
    text-align: center;
    max-width: 450px;


    @media only screen and (max-width: $mobile) {
      width: 80%;
      margin: auto;
    }   
  }
}
