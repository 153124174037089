$new-insured-color: $cwColor-base-blue !default;
$new-insured-hover-color: $cwColor-black-dark !default;

.insured-list-page {
  &__button-title {
    font-weight: normal;

    &:before {
      transition: all .2s ease-out;
    }

    svg {
      fill: $new-insured-color;
      margin-right: 5px;
    }
  }

  &__button {
    &:hover {
      svg {
        fill: $new-insured-hover-color;
      }
    }
  }
}
