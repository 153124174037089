.wc-general-questions-page {
  .page-title {
    padding: 0;
    margin-top: 0;
  }

  .row-questions {
    @extend .row-group, .row-group--white;
    padding: 10px;

    /* Webkit browsers */
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Mozilla browser */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  &__payroll{
    @include dynamic-tooltip(550px, 460px) ;
  }
}
