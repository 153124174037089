.geosuggest {
  position: relative;

  .geosuggest__suggests {
    position: absolute;
    border: 1px solid $cwColor-black-medium;
    background-color: $cwColor-base-white;
    width: 100%;
    max-width: 100%;
    max-height: 280px;
    padding-left: 0;
    margin-top: 0;
    overflow-y: auto;
    font-size: 14px;
    z-index: 1;

    @media only screen and (max-width: $mobile) {
      width: 100%;
      max-width: 100%;
    }

    &:empty {
      display: none;
    }
  }
  .geosuggest__suggests--hidden {
    display: none;
  }

  .geosuggest__item {
    padding: 5px 10px;
    cursor: pointer;

    &--active,
    &:hover {
      background-color: $cwColor-blue-light;
      color: $cwColor-base-white;
    }
  }
}
