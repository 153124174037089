.page-notification {
  margin-top: 8px;

  border-left: 1px dotted $cwColor-black-light;
  border-right: 1px dotted $cwColor-black-light;

  padding: 0 10px;

  @media only screen and (max-width: $tablet){
    border-right: none;

    .form-help-icon {
      display: none;
    }
  }

  @media only screen and (max-width: $mobile) {
    width: 100%;
    border: none;
    margin-top: 1px;
    margin-bottom: 7px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
