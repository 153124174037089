header {
  @include qf-clearfix();
  display: block;
  font-size: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
  z-index: 999;
  padding: 0 26px;

  @media only screen and (max-width: $mobile) {
    min-height: 40px;
    width: 100%;
    background-color: $cwColor-base-white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    position: relative;

    .header-icon {
      position: absolute;
      top: calc(50% + 2px);
      transform: translateY(-50%);
      font-size: 20px;
      cursor: pointer;
    }

    .left {
      left: 10px;
    }

    .right {
      right: 10px;
    }

    svg.close {
      margin-top: 15px;
      fill: $cwColor-base-blue;

      &:hover {
        fill: $cwColor-blue-light;
      }
    }
  }

  .logo {
    min-height: 1px;
  }

  &.open {
    box-shadow: 0 5px 5px 0 rgba(50, 50, 50, 0.2);
    padding-bottom: 10px;
  }

  .navbar-header {
    float: left;
    padding: 13px;

    @media only screen and (max-width: $tablet){
      padding: 10px 13px 0 13px;
    }

    @media only screen and (max-width: $mobile) {
      width: 100%;
      float: none;
    }

    .navbar-header__link {
      display: block;

      @media only screen and (max-width: $mobile) {
        pointer-events: none;
      }

      .logo.logo--auto-sized {
        height: auto;
        width: auto;
      }
    }

    .navbar-header__logo {
      display: block;
      height: 34px;
      width: 160px;
      vertical-align: middle;

      @media only screen and (max-width: $mobile) {
        display: none;
      }

      &--mobile {
        display: none;

        @media only screen and (max-width: $mobile) {
          display: block;
          margin: -2px auto 0 auto;
          width: 140px;
          height: auto;
        }
      }
    }

    &--c4a {
      padding-top: 5px;

      .navbar-header__logo {
        @media only screen and (max-width: $mobile) {
          height: 34px;
          width: auto;
        }
      }
    }
  }
}

.agent-header {
  background-color: #438bd7;
  color: white;
  font-size: 14px;
  padding: 10px 12px;

  @include qf-clearfix();

  @media only screen and (max-width: $mobile) {
    padding-top: 46px;
  }

  span {
    padding-right: 30px;
    font-weight: 100;
    line-height: 28px;
  }
}

.header-container {
  z-index: 999;

  .program-banner button:hover {
    background-color: transparent;
    border: none;
  }
}
