.right {
  float: right;
}

.hidden {
  display: none;
}

.mobile-hidden {
  @media only screen and (max-width: $mobile) {
    display: none;
  }
}

.form-help-icon {
  &--pulled-right {
    @media screen and (min-width: 1500px) {
      right: initial !important;
      left: initial;
      margin-left: 900px;
    }
  }
}
