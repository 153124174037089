.renewals-info-page {
  .page-title {
    padding: 0;

    @media only screen and (max-width: $mobile) {
      margin-top: 40px;
    }

    &+ .page-description {
      width: 77%;

      @media only screen and (max-width: $tablet){
        width: 100%;
      }
    }
  }

  &__row {
    position: relative;
    margin-bottom: 0;
    @extend .row-group, .row-group--white;

    .radio-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: $mobile) {
        flex-direction: column;
      }
     
      .form-group {
        max-width: 38%;

        @media only screen and (max-width: $mobile) {
          max-width: 100%;
        }

        label {
          font-size: 18px;
          font-weight: lighter;
          padding-bottom: 8px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
}
