.continue-button {
  @extend .link;
  display: inline-block;
  float: right;
  min-width: 120px;
  text-align: center;

  &.btn--disabled {
    @extend .btn, .btn[disabled];
  }

  &--mobile-link {
    @media only screen and (max-width: $tablet){
      &.btn--disabled {
        color: $cwColor-base-white;
        background-color: $cwColor-base-orange;
        border: 1px solid $cwColor-base-orange;
      }
    }
  }
}

.step-switcher {
  &--top {
    .continue-button--mobile-link {
      @media only screen and (max-width: $mobile) {
        color: $cwColor-base-blue;
        background-color: transparent;
        border: none;
        padding: 0;
        font-size: 13px;
        text-align: right;
        line-height: 42px;


        &.btn--disabled {
          font-size: 14px;
          color: $cwColor-black-medium;
          background-color: transparent;
          border: none;
          padding: 0;
        }
      }
    }
  }

  &--bottom {
    .continue-button--mobile-link, .back-link {
      @media only screen and (max-width: $mobile) {
        width: 100%;
        text-align: center;
      }
    }
  }
}
