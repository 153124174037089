.radio-help-list {
  font-size: 14px;
  margin-left: 20px;
  padding: 0 16px 10px 0;

  &__item {
    list-style-type: circle;

    > .radio-help-list {
      margin-left: 18px;
      margin-top: 3px;
      padding-bottom: 3px;

      .radio-help-list__item {
        list-style-type: disc;
      }
    }
  }
}

.radio-help-list--spacious {
  .radio-help-list__item {
    margin: 5px 0;
  }
}
