.back-link {
  @extend .link;
  display: table;
  float: right;
  font-size: 14px;
  margin: 10px 15px 13px 0;
  color: $cwColor-base-blue;
  cursor: pointer;

  @media only screen and (max-width: $tablet){
    float: none;
    margin: 25px 0 0 0;
    font-size: 13px;

    &--top {
      margin: 0;

      @media only screen and (max-width: $mobile) {
        line-height: 42px;
      }
    }

    &--bottom {
      margin: 8px 0 0 0;

      @media only screen and (max-width: $mobile) {
        line-height: 42px;
      }

    }
  }
}
