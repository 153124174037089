// Main colors
$primary-color: #0083a9;
$primary-dark-color: #1e657a;
$secondary-color: #d2d2d2;
$primary-light-color: #e6f4f8;
$primary-extra-light-color: #f3f9fA;
$action-color: #d0352f;
$action-dark-color: #BA2F2A;
$base-orange-color: #f3a309;
$border-color: #b8dae4;

// Gray scale colors
$dark-color: #505050;
$dark-medium-color: #8a8a8a;
$dark-light-color: #bfbfbf;
$gray-color: #d2d2d2;
$gray-medium-color: #f0f0f0;
$gray-light-color: #f9f9f9;
$white-color: #fff;

// Notifications Color
$notice-error-light-color: #f8e0e0;
$notice-error-dark-color: #de4242;
$notice-info-light-color: #e2e9f2;
$notice-info-dark-color: #0083a9;
$notice-success-light-color: #dbf1d5;
$notice-success-dark-color: #68c14d;
$notice-success-brilliant-color: #a4dc00;
$warning-dark-color: #ffd44e;

$cwColor-gray-background: #f8f8f8;

$summary-icon-color: $primary-color;

$new-insured-color: $primary-color;
$new-insured-hover-color: $white-color;

// SubAgentHeader colors
$sub-agent-header-color: #d0352f;
$sub-agent-header-color--light: #9a2420;
$sub-agent-header-color--dark: #9a2420;
