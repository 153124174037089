.disclosure {
  page-break-before: always;

  &__title {
    padding: 12px;
  }

  &__paragraph {
    padding: 12px;

    &--agreement {
      padding: 0 8px;
      margin-bottom: 10px;
      font-size: 14px;

      p {
        margin-bottom: 10px;
      }
    }
  }
}
