.progress-bar {
  padding: 0 6px;
  text-align: center;

  &--top {
    display: none;

    @media only screen and (max-width: $mobile)  {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }

  &__title {
    display: inline-block;
    margin: 12px;
    font-size: 14px;
    text-align: center;

    @media only screen and (max-width: $mobile) {
      margin: auto;
      font-size: 12px;
    }
  }

  &__title-mobile {
    display: inline-block;
    padding-right: 4px;

    @media only screen and (max-width: $mobile) {
      display: none;
    }
  }

  &__bar {
    height: 5px;
    background-color: $cwColor-black-light;
    margin-bottom: 16px;
    border-radius: 3px;

    @media only screen and (max-width: $mobile) {
      order: -1;
      margin: 0;
      border-radius: 0;
      box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.20);
    }
  }

  &__completed {
    height: 5px;
    background-color: $cwColor-base-blue;
    border-radius: 3px;

    @media only screen and (max-width: $mobile) {
      border-radius: 0;
    }
  }
}
