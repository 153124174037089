.type-insurance {
    border-bottom: 1px solid $cwColor-black-medium;
    margin: 60px 0 20px;
    padding-bottom: 10px;

    img {
        width: 80%;
        display: block;
        margin: 0 auto;
    }
    h1 {
        font-size: 18px;
        line-height: 24px;
        padding: 0 8px;
        text-align: center;
    }
}