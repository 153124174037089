.renewals-signature-page, .signature-page {
  &__label {
    font-weight: bold;
  }

  .disclosure {
    overflow-y: scroll;
    height: 360px;

    &__title {
      font-weight: bold;
      font-size: 14px;
      padding: 0 0 0 12px;
      margin: 0;
    }

    background:
      linear-gradient(white 30%, rgba(255,255,255,0)),
      linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;

    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;

    ul {
      margin-left: 30px;
    }
  }

  .page-title {
    padding: 0;

    @media only screen and (max-width: $mobile) {
      margin-top: 30px;
    }
  }

  .form {
    label {
      margin-bottom: 6px;
    }
  }

  li {
    margin-bottom: 10px;
  }
}
