#df-qf_aus_property_address {
  ul[data-test="rc-gnaf-list"] {
    padding: 0;
    margin: 0;
  }
}

#df-qf_aus_business_address {
  ul[data-test="rc-gnaf-list"] {
    padding: 0;
    margin: 0;
  }
}
