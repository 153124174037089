$cwColor-base-green: #60d05a;
$cwColor-disabled: #f0f0f0;
$cwColor-text-disabled: #9B9B9B;
$cwColor-blue-selected: #428AD8;
$cwColor-base-blue: #438bd7;
$cwColor-blue-light: #8ECCF1;
$cwColor-blue-lighter: #E9F7FF;
$cwColor-white: #ffffff;
$cwColor-grey-helper: #8A8A8A;
$cwColor-gray-color: #d2d2d2;
$cwColor-red-medium: #de4242;
$cwColor-blue-xlight: #ddecf5;
$cwColor-green-light: #68c14d;
$cwColor-green-xlight: #dbf1d5;
$cwColor-red-xlight: #f8e0e0;
$cwColor-black-light: #d2d2d2;
