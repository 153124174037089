@import 'printView';
@import 'stepsSwitcher';

.back-link {
  color: $primary-color;
  line-height: 42px;
  margin: 1px 26px 0 0;
}

nav .lifecycle ul.list .item.current .circle.zoomIn {
  background: $primary-color;
}

nav .lifecycle ul.list .item.complete .circle {
  background: $primary-dark-color;
  border-color: $primary-dark-color;
}

nav .lifecycle ul.list .item.current .circle {
  background: $primary-color;
  border-color: $primary-color;
}

.oc-select {
  .oc-select__search {
    border-color: $dark-light-color;
  }

  .oc-selected-value {
    background: $primary-color;
    font-size: 14px;
    line-height: 23px;
    height: 25px;
    padding-top: 0;
    padding-bottom: 0;

    .oc-selected-value__close-icon {
      padding: 5px 5px 3px 8px;
    }
  }

  .oc-icon-options {
    margin: 5px -22px 5px -7px;
    display: flex;
    align-items: stretch;

    @media only screen and (max-width: $tablet){
      margin: 0 1%;
    }
  }

  .oc-icon-options .oc-icon-option-container {
    width: 18.31111%;

    @media only screen and (max-width: $tablet){
      width: 23%;
    }

    @media only screen and (max-width: $mobile) {
      width: 47%;
    }
  }

  .oc-icon-option {
    &.oc-icon-option--checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    &--no-touch {
      &:hover:not(.oc-icon-option--disabled) {
        background-color: $primary-light-color;

        .oc-icon-option__icon:before {
          color: $dark-medium-color;
        }

        .oc-icon-option__label {
          color: $dark-medium-color;
        }
      }

      &.oc-icon-option--checked {
        &:hover:not(.oc-icon-option--disabled) {
          background-color: $primary-color;

          .oc-icon-option__label {
            color: $white-color;
          }

          .oc-icon-option__icon:before {
            color: $white-color;
          }
        }
      }
    }
  }

  .oc-icon-options__item .oc-icon-options__item-help-icon:before {
    color: $primary-color;
  }
}

%option {
  background-color: $white-color;
  color: $dark-color;
}

%option--checked {
  background-color: $primary-color;
  color: $white-color;
}

%option--hover {
  background-color: $primary-light-color;
  color: $dark-color;
}

.oc-list-option--checked {
  @extend %option--checked;
  border-color: $primary-color;

  &:hover:not(.oc-list-option--disabled) {
    @extend %option--checked;
    border-color: $primary-color;
  }
}

.oc-list-option--no-touch:not(.oc-list-option--disabled):hover {
  @extend %option--hover;

  @media only screen and (max-width: $mobile) {
    background-color: $white-color;
    color: $dark-color;
  }
}

.progress-bar__completed {
  background-color: $primary-color;
}

.keyboard .keyboard__number:hover,
.keyboard .keyboard__delete:hover {
  color: $dark-color;
  background-color: $primary-light-color;
}

.keyboard .keyboard__delete:hover:before {
  color: $dark-color;
  background-color: $primary-light-color;
}

.oc-list-option {
  .oc-option__next-icon {
    color: $primary-light-color;
  }

  .oc-option__help-icon {
    &:before {
      color: $primary-dark-color;
    }

    @media only screen and (max-width: $tablet){
      display: none;
    }
  }
}

.progress-bar {
  & &__bar {
    @media only screen and (max-width: 920px) {
      border-top: 1px solid #dfdfdf;
      background-color: $gray-medium-color;
      box-shadow: 0px 2px 2px 0px rgba(50, 50, 50, 0.2);
    }
  }

  & &__completed {
    @media only screen and (max-width: 920px) {
      background-color: $primary-color;
    }
  }
}

//Buttons
.btn.continue-button.disabled {
  &:hover {
    cursor: default;
    color: $white-color;
    background-color: $gray-color;
    border: 1px solid $gray-color;
  }
}

.btn-group {
  display: inline-block;
  button,
  .btn {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.btn {
  font-size: 14px;
  border-radius: 3px;
  color: $white-color;
  background-color: $action-color;
  border: 1px solid $action-color;
  display: inline-block;

  &.btn--blue {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: $white-color;

    &:hover {
      color: $white-color;
      background-color: $primary-dark-color;
      border: 1px solid $primary-dark-color;
    }
  }

  &.btn--border {
    background: transparent;
    color: $action-color;
    border: 1px solid $action-color;

    &:hover {
      background-color: $action-dark-color;
      color: $white-color;
      border: 1px solid $action-dark-color;
    }
  }

  &.btn--border-blue {
    background: transparent;
    color: $primary-color;
    border: 1px solid $primary-color;

    &:hover {
      color: $white-color;
      background-color: $primary-dark-color;
      border: 1px solid $primary-dark-color;
    }
  }

  &.btn--small {
    font-size: 14px;
    padding: 8px 12px;
  }

  &.disabled,
  &[disabled] {
    cursor: default;
    background-color: $gray-color;
    border: 1px solid $gray-color;
  }
}

.keyboard .keyboard__number:focus,
.keyboard .keyboard__number:active,
.keyboard .keyboard__delete:focus,
.keyboard .keyboard__delete:active {
  animation-name: none;
}

.modal.modal--fullscreen-mobile {
  @media only screen and (max-width: $mobile) {
    position: fixed;
  }
}

.recommend-coverage-page {
  .recommend-coverage-options {
    & .icons-radio-input {
      padding-top: 0px;
    }
  }
}

.hidden-logo-desktop {
  display: flex !important;
  justify-content: center;
  margin-top: 7px;

  @media only screen and (max-width: $mobile) {
    display: none !important;
  }
}

header {
  .navbar-header {
    @media only screen and (max-width: $mobile) {
      padding-top: 5px;
    }

    .navbar-header__logo {
      height: initial;
      width: 126px;

      &--mobile {
        @media only screen and (max-width: $mobile) {
          width: 74px;
          height: 24px;
          margin-top: 5px;
        }
      }
    }
  }
}

a {
  color: $primary-color;
}

.agent-nav-box {
  margin-top: 46px;

  @media only screen and (max-width: $mobile) {
    margin-top: 0px;
  }

  &__body-info a {
    color: $primary-color;
  }

  &__action-item {
    &, &--contact:before, &--email:before, &--call:before, &--chat:before {
      color: $primary-color;
    }
  }
}

.done-page .detail-panel h5 .edit:before,
.df-summary .detail-panel h5 .edit:before,
.form .form-help-icon:before,
.contact-info-page .help-info a,
.insurance-page .help-info a {
  color: $primary-color;
}

@media screen and (min-width: $tablet) {
  a.link:hover {
    color: $primary-dark-color;
 }
  a:hover {
    color: $primary-dark-color;
 }
  a.continue-button:hover {
    color: $white-color;
 }
  .btn.continue-button:hover {
    background-color: $action-dark-color;
    border: 1px solid $action-dark-color;
 } }
