#main {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;

  #content {
    display: flex;
    flex: 1 0 auto;
    
    @include qf-clearfix();

    @media only screen and (max-width: $mobile) {
      flex-direction: column-reverse;
      padding-bottom: 5px;

      &.agent-body {
        padding: 0 0 20px 0;
      }
    }

    &.landing {
      padding-bottom: 0;

      .page {
        &.page-typeform {
          width: calc(100% - #{$sidebar-width});

          @media only screen and (max-width: $mobile) {
            width: 100%;
          }
        }
      }
    }

    .page {
      position: relative;
      border-left: 1px dotted $cwColor-black-light;
      border-right: 1px dotted $cwColor-black-light;
      width: calc(100% - #{$sidebar-width + $helpPanel-width});

      @media only screen and (max-width: $tablet){
        width: calc(100% - #{$sidebar-width});
        border-right: none;

        .form-help-icon {
          display: none;
        }
      }

      @media only screen and (max-width: $mobile) {
        position: static;
        width: 100%;
        border: none;
        flex-basis: 100%;
      }

      .page-wrapper {
        padding: 2px 10px 20px 10px;
        position: relative;

        .selector-options,
        .react-datepicker-popper {
          z-index: 1000;
        }
      }
    }

    .loader-container {
      & + .step-switcher--bottom {
        display: none;
      }
    }
  }
}
