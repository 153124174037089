.step-switcher {
  &--bottom {
    margin-top: 16px;
    top: 16px;

    @media only screen and (max-width: $mobile) {
      .continue-button--mobile-link, .back-link {
        width: 100%;
        text-align: center;
      }

      .continue-button,
      .continue-button.btn--disabled {
        justify-content: center;
      }
    }

    @media only screen and (max-width: 920px) {
      .back-link {
        height: 48px;
      }
    }
  }

  &--top {
    .continue-button {
      &--mobile-link {
        @media only screen and (max-width: $tablet){
          font-size: 13px;
          background-color: $action-color;
          border: 1px solid $action-color;
          padding: 0 10px;
          line-height: 48px;
          text-align: center;

          &.btn--disabled {
            font-size: 13px;
            background-color: $action-color;
            border: 1px solid $action-color;
            padding: 0 10px;
            line-height: 48px;
            text-align: center;
          }
        }

        @media only screen and (max-width: $mobile) {
          color: $primary-color;
          background-color: transparent;
          border: none;
          padding: 8px 0;
          font-size: 13px;
          text-align: right;
          line-height: 42px;
          justify-content: end;

          &.btn--disabled {
            color: $primary-color;
            background-color: transparent;
            border: none;
            padding: 8px 0;
            font-size: 13px;
            text-align: right;
            line-height: 42px;
            justify-content: end;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 920px) {
    .continue-button,
    .back-link {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: $mobile) {
    .back-link {
      &--top {
        padding: 8px 0;
      }
    }
  }
}

.page > .step-switcher {
  padding: 0 10px;
}

.page > div + .step-switcher .back-link {
  @media only screen and (max-width: 920px) {
    margin-right: 0;
  }
}
