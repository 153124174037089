@media only screen and (max-width: $tablet){
  .form .input-group.input-group--inline,
  form .input-group.input-group--inline,
  .form .form-group .form-input.form-input--short,
  form .form-group .form-input.form-input--short {
    width: 100%;
    margin: 0;
  }
}

.form {
  .form-group .form-input {
    &--disabled {
      background-color: $cwColor-disabled;
    }
  }
  .form-group {
    &--short {
      .input-group {
        vertical-align: top;
        @media only screen and (max-width: $mobile) {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
      .form-input {
        width: 100px;
        margin-right: 10px;
      }
    }
  }
}

.extra-info.prefilled-values-notice {
  margin-left: 230px;
  @media only screen and (max-width: $mobile) {
    margin-left: 0px;
  }
  color: $cwColor-base-red;
}

.form .row-address .form-group label,
form .row-address .form-group label {
  display: flex;
  height: 28px;
  line-height: 23px;
}

.form .form-group__additional-info,
form .form-group__additional-info {
  margin-top: 5px;
  color: $cwColor-black-medium;
}

.form-input {
  &--mobile {
    width: 31% !important;
  }
}

.form .form-group .form-input__other-activities--disabled {
  text-align: right;
  padding-right: 30px;
  width: 102px;
  background-color: $cwColor-disabled;
  color: $cwColor-text-disabled;

  @media only screen and (max-width: $mobile) {
    width: 100%;
    text-align: left;
  }
}

.form .form-group .form-input__practice-areas--disabled {
  text-align: right;
  padding-right: 30px;
  width: 102px;
  background-color: $cwColor-disabled;
  color: $cwColor-text-disabled;

  @media only screen and (max-width: $mobile) {
    width: 100%;
    text-align: left;
  }
}
