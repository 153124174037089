.insurance-block {
  &__title {
    margin: 10px 0 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 25px;
    color: $cwColor-black-dark;

    @media only screen and (max-width: $tablet){
      text-align: center;
    }
  }

  .page-title {
    padding: 0;
    margin-top: 0;
  }
}
