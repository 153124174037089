.steps-line {
  &__list {
    display: flex;
    justify-content: center;
    padding: 0;
    counter-reset: counter;
    list-style: none;
  }

  &__list-item {
    counter-increment: counter;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 290px;
    white-space: nowrap;

    span {
      display: block;
      margin-bottom: 5px;
      font-size: 13px;
      color: $cwColor-black-medium;
      text-align: center;
      line-height: 1.3em;

      @media only screen and (max-width: $mobile) {
        font-size: 11px;
      }
    }

    &::after {
      content: counter(counter);
      display: block;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $cwColor-base-white;
      color: $cwColor-base-white;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      padding-top: 4px;
      z-index: 2;
    }

    &::before {
      content: ' ';
      display: block;
      border-top: 2px solid $cwColor-black-light;
      z-index: 1;
      position: absolute;
      bottom: 11px;
      left: 50%;
      right: 0;
      width: 100%;
    }

    &:last-child::before {
      display: none;
    }
  }

  &__list-item--previous {
    &::after {
      background: $color-text;
    }

    span {
      color: $color-text;
    }
  }

  &__list-item--current {
    &::after {
      background: $color-text;
      font-weight: 700;
    }

    span {
      font-weight: 500;
      color: $color-text;
    }

    .steps-line__navigation-arrow {
      position: absolute;
      bottom: -20px;
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;

      border-bottom: 17px solid $cwColor-base-white;
    }
  }

  &__list-item--next {
    &::after {
      color: $cwColor-black-light;
      border: 2px solid $cwColor-black-light;
      padding-top: 2px;
    }
  }
}
