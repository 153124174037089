.typeahead {
  position: relative;
  .typeahead__list{
    position: absolute;
    border: 1px solid $cwColor-black-medium;
    background-color: $cwColor-base-white;
    width: 100%;
    max-width: 100%;
    max-height: 280px;
    overflow-y: auto;
    font-size: 14px;
    z-index: 1;

    &:empty {
      display: none;
    }

    li{
      padding: 5px 10px;
      cursor: pointer;
      &:hover{
        background-color: $cwColor-blue-light;
      }
      a{
        color: $color-text;
      }
    }
  }
}

