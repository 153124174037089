.ineligible-aops-page {
  .page-title {
    padding: 0;

    &+ .page-description {
      width: 77%;

      @media only screen and (max-width: $tablet){
        width: 100%;
      }
    }
  }

  &__row {
    padding: 8px 0 8px 8px;
    margin: 3px 0;
    background-color: $cwColor-white;
    width: 100%;

    .radio-group {
      &__icon-group {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
