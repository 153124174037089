.radio-group {
  display: table;

  @media only screen and (max-width: $mobile) {
    width: 100%;
  }

  &--aligned {
    padding-left: 0 !important;
  }

  .form-group {
    padding: 0;
  }

  &__label-container {
    display: table-cell;
    vertical-align: top;

    &--middle {
      vertical-align: middle;
    }

    @media only screen and (max-width: $mobile) {
      display: block;
      padding: 0 10px;

      .label.label--inline {
        margin-bottom: 2px;
      }
    }
  }

  &__icon-group {
    display: table-cell;
    vertical-align: top;

    &--middle {
      vertical-align: middle;
    }

    @media only screen and (max-width: $mobile) {
      display: block;
    }
  }

  &__error-message {
    clear: both;
    color: $cwColor-base-red;
    margin-left: 6px;
    margin-top: 3px;
    font-size: 14px;

    &--left-padding {
      padding-left: 234px;
    }
  }

  &__info {
    display: inline-block;
    float: right;
    width: auto;

    @media only screen and (max-width: $tablet){
      display: block;
    }

    @media only screen and (max-width: $mobile) {
      float: none;
    }
  }

  &__additional-info {
    display: inline-block;
    margin: 8px 0 10px 15px;
    font-size: 13px;
    line-height: 16px;
    color: $cwColor-black-xmedium;

    @media only screen and (max-width: $tablet){
      width: auto;
    }

    @media only screen and (max-width: $mobile) {
      width: 100%;
      margin: 6px 0 10px;
      text-align: center;
    }
  }

  &__details {
    margin-right: 12px;
    border-bottom: 1px dashed #438bd7;
    font-size: 13px;
    color: #438bd7;
    cursor: pointer;
    
      &:hover {
        color: #505050;
        border-bottom: 1px dashed #505050;
      }   
  }

  &__options-list {
    width: 290px;
    margin-left: 5px;

    @media only screen and (max-width: $mobile) {
      width: 100%;
      margin-left: 0;
    }

    .oc-option__next-icon {
      display: none;
    }
  }

  &__bulleted-list {
    padding: 0.2em 12px 0.5em 4px;
    font-size: 14px;
    width: auto;
    list-style: none;


    li:before{
      content: "•";
      padding-right: 5px;
    }

    @media only screen and (max-width: $mobile) {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0 48px;
    }
  }
}
