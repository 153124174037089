.resume-page {
  .page-title {
    display: inline-block;
    margin-bottom: 0;
  }

  .account-action-container {
    padding: 0 10px 0;

    @media only screen and (max-width: $tablet){
      text-align: center;
    }
  }

  .account-link {
    display: inline-block;
    min-width: 120px;
    color: $cwColor-base-white;
    background-color: $cwColor-base-orange;
    border: 1px solid $cwColor-base-orange;
    float: right;
    text-align: center;
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 16px;

    &:hover {
      color: $cwColor-base-white;
      border-color: $cwColor-black-dark;
      background-color: $cwColor-black-dark;
    }

    @media only screen and (max-width: $tablet){
      color: $cwColor-base-blue;
      background-color: transparent;
      border: none;
      float: none;
      padding: 0;
      font-size: 13px;
      margin-top: 30px;
    }
  }

  .page-description {
    padding-top: 10px;
  }
  .row-done {
    padding: 10px 10px 2px;
  }
  .binder-action-container {
    @include qf-clearfix();

    @media only screen and (max-width: $tablet){
      text-align: center;
      margin-bottom: 10px;
    }

    &__comment {
      display: inline-block;
      margin: 15px 0 15px;

      @media only screen and (max-width: $tablet){
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  .binder-action {
    float: right;
    text-align: center;

    @media only screen and (max-width: $tablet){
      width: 100%;
    }
  }
}
