@mixin only_ie {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	  @content;
  }
}

@mixin only_edge {
	@supports (-ms-ime-align:auto) {
		@content;
	}
}

@mixin only_ms_browsers {
	@include only_ie {
		@content;
	}

	@include only_edge {
		@content;
	}
}

@mixin qf-clearfix {
	&::after {
	  clear: both;
	  content: "";
	  display: block;
	}
}