.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 6px;
  outline: none;
  padding: 8px 20px 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  @media only screen and (max-width: $tablet){
    min-width: 600px;
  }

  &--fullscreen-mobile {
    @media only screen and (max-width: $mobile) {
      top: 0;
      left: 0;
      height: 100%;
      width: 100vw;
      transform: none;
      border-radius: 0;
    }
  }

  &__web-to-case {
    &--form {
      min-width: 500px;
    }

    &--step {
      height: 270px;
      width: 600px;
    }
  }
}

.modal-close-webview {
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  transform: none;
  border-radius: 0;
  padding: 16px;
  border: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &-content {
    width: 100%;
    height: 100%;
    padding: 0;

    .btn-close {
      width: 40px;
      height: 40px;
      position: absolute;
      top: -3px;
      right: -3px;
    }

    .popup__content {
      width: 100%;
      height: 100%;
    }
  }
}

.modal-layout {
  padding: 0px;

  &__body {
    display: flex;
    flex-direction: column;

    @media (min-width: $mobile) {
      max-width: 650px;
      flex-direction: row;
    }
  }

  &__sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 24px 0px;
    order: 1;

    @media (min-width: $mobile) {
      padding-right: 16px;
      padding-left: 16px;
      flex-grow: 0;
      min-width: 120px;
      max-width: 120px;
      order: -1;
    }
  }

  &__main {
    padding: 24px 0px;

    @media (min-width: $mobile) {
      padding-right: 16px;
      padding-left: 16px;
      flex-grow: 1;
    }
  }

  &__footer {
    padding: 24px 0px;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: lightgrey;
      display: block;
      position: relative;
      top: -24px;
    }

    @media (min-width: $mobile) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

.modal-title {
  margin: 0px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 100;
  font-family: $principal-fonts;
  color: $cwColor-base-bluegray;
  margin-bottom: 16px;
}

.modal-form {
  &__content {
    padding: 0px;
    &--center {
      text-align: center;
    }
    &--small {
      width: 100%;
      height: 100%;
    }
    @media (max-width: $mobile) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 20;
      width: 100vw;
      height: 100vh;
      overflow: scroll;
      border-radius: 0;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
    .btn-close {
      top: 6px;
      right: 1px;
      height: 39px;
      width: 39px;
    }
  }
  &__title {
    color: #505050;
    text-align: center;
    font-size: 28px;
    margin: 0;
    font-weight: 400;
    padding: 26px 0 12px;
    line-height: 41px;
  }
  &__response-title {
    text-align: center;
    font-size: 22px;
    line-height: 1.36;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 400;
    @media (max-width: $mobile) {
      margin-top : 56px;
    }
  }
  &__response-description {
    font-size: 18px;
    line-height: 1.33;
    font-weight: 300;
  }
  &__step-wrapper {
    padding: 37px 26px 0px;
    display: flex;
    flex-direction: column;
  }
  &__select {
    margin: 0 auto;
    width: 320px;
    text-align: left;
    font-size: 14px;
  }
  &__text-area {
    margin: 12px 0 0;
    width: 320px !important;
    height: 206px !important;
    font-weight: 400 !important;
    padding: 10px !important;
  }
  &__button {
    width: 160px;
    height: 42px;
    margin: 16px 0 15px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    &:focus {
      outline: none;
    }
    &--margin-top {
      margin-top: 32px;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 320px;
    margin: 0 auto;
  }
  &__description {
    text-align: center;
    font-size: 14px;
    color: #aaa;
    width: 320px;
  }
  &__form {
    .help-block {
      text-align: left !important;
    }
  }
}
