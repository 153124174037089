.signature {
  margin: 0 8px;
  padding: 40px 0 0;
  border-top: 1px solid $cwColor-black-mlight;
  page-break-inside: avoid;

  &__value {
    font: italic 20px "Arial";
  }

  &__split-line {
    width: 300px;
    margin: 7px 0 2px;
    border-top: 1px solid $cwColor-black-light;
  }

  &__label {
    font: bold 13px "Arial";
    color: $cwColor-black-light;
    line-height: 16px;
  }
}
