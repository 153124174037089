.page-typeform {

  .typeform-widget {
    height: 100vh;
    margin: 0;
    padding: 0;
    border: none;
    display: block;
    width: 100%;

    @media only screen and (max-width: $mobile) {
      height: calc(100vh - 50px);
    }
  }
}
