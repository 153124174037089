.lawyer-form-page {
  & .radio-group {
    padding: 16px 0;
  }

  &__name {
    display: table-cell;
    min-width: 360px;
  }

  & .employmentType {
    padding: 8px;
    font-size: 12px;
    width: 132px;
  }
}
