.print-view {
  background-color: $cwColor-base-white;

  &__logo {
    text-align: center;
    height: 30px;
    margin-bottom: 10px;

    img {
      width: 800px;
      height: 30px;
      max-height: initial !important;
      max-width: initial !important;
    }
  }

  &__header {
    padding: 20px 8px;
  }

  &__header-logo {
    width: 150px;
    margin-bottom: 10px;
  }

  &__header-logo--secondary {
    float: right;
  }

  &__header-contact-info {
    font-size: 13px;
    color: $cwColor-black-xmedium;

    a {
      color: $cwColor-black-xmedium;
    }
  }

  .page-title {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: center;
  }

  .page-timestamp {
    margin-left: 8px;
    font-size: 0.75rem;
  }
}
