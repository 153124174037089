.btn-group {
  display: inline-block;

  button, .btn {
      margin-right: 10px;

      &:last-child {
          margin-right: 0;
      }
  }
}
button, .btn {
  @include transition(none);
  font-size: 14px;
  border-radius: 3px;
  color: $cwColor-base-white;
  background-color: $cwColor-base-orange;
  border: 1px solid $cwColor-base-orange;
  padding: 10px 20px;
  display: inline-block;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;

  &.btn--blue {
      background-color: $cwColor-base-blue;
      border: 1px solid $cwColor-base-blue;
      color: $cwColor-base-white;
  }
  &.btn--blue-light {
      background-color: $cwColor-blue-light;
      border: 1px solid $cwColor-blue-light;
      color: $cwColor-base-white;
  }
  &.btn--border {
      background-color: $cwColor-base-white;
      color: $cwColor-base-orange;
      border: 1px solid $cwColor-base-orange;
  }
  &.btn--border-blue {
      background-color: $cwColor-base-white;
      color: $cwColor-base-blue;
      border: 1px solid $cwColor-base-blue;
  }
  &.btn--border, &.btn--border-blue {
      &:hover {
          @include media($desktop) {
              background-color: $cwColor-base-white;
              color: $cwColor-black-dark;
              border: 1px solid $cwColor-black-dark;
          }
      }
  }
  &.disabled, &[disabled] {
      cursor: default;
      background-color: $cwColor-black-light;
      border: 1px solid $cwColor-black-light;
  }
  &:hover {
   @include media($desktop) {
      border: 1px solid $cwColor-black-dark;
      background-color: $cwColor-black-dark;
      color: $cwColor-base-white;
    }
  }
  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}
