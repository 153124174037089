$summary-icon-color: $cwColor-base-blue !default;
$summary-background-color: $cwColor-base-white !default;

.summary-item {
  padding: 10px 20px 15px 20px;
  background: $summary-background-color;
  margin-bottom: 10px;

  &__header-title {
    margin: 0;
  }

  &__header {
    margin-top: 0;
    position: relative;

    @media only screen and (max-width: $mobile) {
      @include qf-clearfix();

      &-title {
        float: left;
        margin: 5px 0;
      }
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: $tablet){
      display: block;
    }
  }

  .col {
    font-size: 14px;
    margin: 0.35rem 0;
    font-weight: lighter;
    width: 33.3%;

    .value {
      color: $cwColor-black-xmedium;
    }

    @media only screen and (max-width: $tablet){
      display: table;
      width: 100%;
      padding: 5px 0;
      font-size: 13px;

      .label {
        display: table-cell;
        width: 60%;
        padding: 2px 0;
        font-weight: normal;
        vertical-align: top;
      }

      .value {
        vertical-align: bottom;
        text-align: right;
        float: right;
      }
    }
  }
}
