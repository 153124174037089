.done-page,
.df-summary,
.resume-page {
  .page-title {
    color: #4d4d4d;
    padding: 16px 0;

    @media only screen and (max-width: $mobile) {
      font-size: 18px;
      line-height: 26px;
      margin: 0;
    }
  }

  p {
    font-size: 14px;
    line-height: 20px;

    .resume-content {
      display: inline-block;
      margin-top: 15px;
    }
  }

  .page-timestamp-renewals {
    color: black;
  }

  h5 {
    font-size: 18px;
    line-height: 25px;
    font-weight: 100;
    margin: 0 0 10px;

    @media only screen and (max-width: $tablet){
      margin: 0 0 10px 10px;
    }
  }

  .row-auth {
    @include qf-clearfix();
    > div {
      @media only screen and (max-width: $tablet){
        min-height: 1px;
      }
    }

    .error input {
      border: 1px solid $cwColor-base-red;
    }

    .form-group {
      padding-bottom: 3px;
    }

    &__error {
      @media only screen and (max-width: $tablet){
        min-height: 1px;
      }
      font-size: 14px;
      line-height: 20px;
    }
  }

  .row-notes {
    .form-group {
      width: 100% !important;
      padding-bottom: 3px;

      textarea {
        height: 100px !important;
        position: relative !important;
        bottom: 0 !important;
        width: 100% !important;
        padding-top: 10px;
      }

      > div {
        display: flex;
      }
    }
  }

  .detail-panel {
    padding: 12px 0 12px 12px;
    margin-bottom: 4px;
    position: relative;
    line-height: 30px;
    background-color: $cwColor-base-white;
    @include qf-clearfix();

    @media only screen and (max-width: $tablet){
      padding: 8px;
      margin: 0 0 4px;
    }

    &--renewals {
      margin-top: 10px;
    }

    h5,
    h6 {
      margin-top: 0;
      margin-bottom: 5px;

      @media only screen and (max-width: $tablet){
        margin-left: 0;
      }
    }

    h5 {
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    h6 {
      font-size: 14px;
      line-height: 21px;
    }

    h5.detail-panel-title {
      color: #4d4d4d;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;

      @media only screen and (max-width: $mobile) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 16px;
      }
    }

    &.other-business-detail-panel {
      h6 {
        .edit {
          top: 15px;
          right: 3px;
        }
      }
    }

    &.general-panel,
    &.additional-info-panel,
    &.bop-panel,
    &.ca-panel,
    &.multi-class-panel {
      .content {
        position: relative;

        .row {
          @include qf-clearfix();
          display: block;

          .col {
            display: table;
            width: 100%;
            font-size: 14px;
            line-height: 23px;
            color: $cwColor-black-dark;

            @media only screen and (max-width: $mobile) {
              font-size: 13px;
            }
          }
        }

        .flex {
          @include qf-clearfix();
          display: flex;
          flex-wrap: wrap;
          align-items: stretch;
          align-content: space-between;
          justify-content: flex-start;

          @media only screen and (max-width: $tablet){
            width: 100%;
          }
        }

        .col {
          display: table;
          width: 100%;
          padding: 0;
          font-size: 14px;
          line-height: 23px;
          color: $cwColor-black-dark;

          @media only screen and (max-width: $mobile) {
            font-weight: normal;
            font-size: 13px;
            border-bottom: 1px solid $cwColor-black-mlight;
          }

          &:last-child {
            border-bottom: none;
          }

          .col {
            padding: 0;
          }

          span,
          .value {
            display: table-cell;
            width: 40%;
            padding: 2px 0;
            font-weight: normal;
            word-break: break-word;

            @media only screen and (max-width: $mobile) {
              vertical-align: bottom;
              font-size: 13px;
              font-weight: normal;
              text-align: right;
            }

            .address {
              font-weight: normal;
            }
          }

          span:first-child {
            font-size: 14px;
            text-align: left;
            vertical-align: top;
            border-right: 1px solid $cwColor-black-mlight;

            @media only screen and (max-width: $mobile) {
              font-size: 13px;
              border-right: 0;
            }
          }

          span:last-child {
            padding-left: 10px;
            vertical-align: bottom;
          }

          .value-click {
            padding: 0 !important;
            text-align: left !important;

            a {
              color: $cwColor-base-blue;
              font-size: 14px;
              font-weight: bold;
            }
          }

          &--full {
            span:last-child {
              padding-left: 0;
              vertical-align: bottom;
            }
            span:first-child {
              border-right: none;
            }
          }
        }
      }
    }

    &.insurance-policies-panel {
      .value {
        font-weight: normal;
        margin-right: 5px;
        font-size: 14px;
      }
    }
    &.bop-panel,
    &.ca-panel,
    &.general-panel,
    &.bop-panel,
    &.ca-panel,
    &.additional-info-panel {
      @media only screen and (max-width: $mobile) {
        .content > div {
          border-bottom: 1px solid $cwColor-black-mlight;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .details,
      .location-details {
        &__title {
          margin: 0;
          padding: 10px 0 0 0;
          width: 50%;
          font-weight: 700;
          color: $cwColor-black-dark;
          border-right: none;

          @media only screen and (max-width: $mobile) {
            margin: 10px 0 5px 0;
            padding: 0;
            width: 100%;
            border-bottom: none;
          }
        }

        &__header {
          padding: 26px 0 0 0;
        }
      }
    }

    &__bullets, .radio-help-list {
      font-size: 13px;
      margin: 0px 18px;
      line-height: 22px;
    }

    .radio-help-list__item {
      list-style-type: disc;
    }
  }

  .row-submit--bottom {
    display: block;
    width: auto;
    margin: 0 10px;

    @media only screen and (max-width: $mobile) {
      margin-top: 0;

      button.btn.right {
        width: 100%;
      }
    }
  }

  ul {
    margin: 9px 18px;
    line-height: 26px;
  }

  .additional-info-panel {
    margin-bottom: 0;
  }

  &--renewals {
    .signature {
      border-top: none;
    }
  }
}

.print-view {
  .df-summary .detail-panel.general-panel .content .col,
  .done-page .detail-panel.general-panel .content .col {
    span:first-child {
      border-right: none;
    }

    span.value {
      padding-left: 10px;
    }
  }
}
