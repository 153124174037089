.practice-areas-supplemental-page {
  .page-title {
    padding: 0;

    &+ .page-description {
      width: 77%;

      @media only screen and (max-width: $tablet){
        width: 100%;
      }
    }
  }

  &__section {
    border-top: 2px solid #0083a9;
    background-color: $cwColor-white;
    padding: 10px 10px 0;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    &:nth-last-child(2) {
      .section-row {
        input {
          background-color: #f0f0f0;
        }
      }
    }

    .section-title {
      padding: 5px 0;
      line-height: 24px;
      font-weight: bold;
    }

    .section-row {
      position: relative;
      display: flex;
      border-bottom: 1px solid #F0F0F0;

      .section-header {
        padding: 10px 0;
        line-height: 24px;
        color: #bfbfbf;
        min-width: 320px;
        max-width: 320px;

        @media only screen and (max-width: $tablet){
          display: none;
        }
      }

      label {
        display: table-cell;
        min-width: 320px;
        max-width: 320px;
        text-align: left;

        &.label--total {
          text-align: right;
        }
      }
    }

    .section-row:last-child {
      border: none;
    }
  }
}
