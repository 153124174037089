@mixin dynamic-tooltip(
	$larger-box-tooltip-position: 590px,
	$regular-tooltip-tooltip-position: 500px,
	$textarea-box-tooltip-position: 800px,
	$form-help-icon-position-right: initial
) {
	.form-help-icon {
		margin-left: 450px;
		right: $form-help-icon-position-right;
	}
	
	.larger-box {
		@extend .form-help-icon;
		margin-left: $larger-box-tooltip-position;
	}

	.textarea-box {
		@extend .form-help-icon;
		margin-left: $textarea-box-tooltip-position;
	}

	.regular-tooltip {
		@extend .form-help-icon;
		margin-left: $regular-tooltip-tooltip-position;
	}
	
	.is-active-image {
		animation: none;
		transform: scale(1);
		opacity: 1;
		visibility: visible;
	}
}
