* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html,
body {
  box-sizing: border-box;
  color: $cwColor-black-dark;
}
body {
  background: $cwColor-black-xlight;
}

::placeholder {
  color: $cwColor-black-xmedium;
  text-shadow: none;
}

.styleguide-icon-add {
  display: inline-block;
  margin-bottom: 6px;

  &:before {
    font-size: 38px;
  }
}

.error {
  color: $cwColor-base-red !important;
}

.hidden-logo-desktop {
  display: block !important;
}

.hidden-logo-mobile {
  display: none !important;
}

@media only screen and (max-width: $mobile) {
  .hidden-logo-desktop {
    display: none !important;
  }

  .hidden-logo-mobile {
    display: block !important;
  }
}

button[type=tertiary]:hover {
  border: none;
  background: transparent;
}

@media only screen and (max-width: $mobile) {
  // Turn off animate.css on mobile which fix bugs and improve performance
  .animated {
    transition-property: none !important;
    transform: none !important;
    @include animation(none !important);
  }
}

.central-container {
  width: 1170px;
  padding: 0 15px;
  margin: 0 auto;

  @media only screen and (max-width: $tablet){
    width: 100%;
  }
}

.error-page {
  background: $cwColor-black-xlight;
  text-align: center;
  border-left: 1px dotted $cwColor-black-light;
  margin-left: 240px;
  height: 100vh;

  @media only screen and (max-width: $mobile) {
    margin-left: 0;
    border-left: 0;
  }

  &_full-width {
    margin-left: 0;
    border-left: none;
  }

  &__image {
    margin: 100px auto 0 auto;
    width: 192px;
    height: 174px;
  }

  &__title {
    color: $cwColor-base-black;
    font-size: 34px;
    font-weight: 100;
    margin-top: 15px;
    padding-top: 0;
  }

  &__info {
    visibility: visible;
    animation-duration: 300ms;
    animation-name: fadeInUp;
    color: $cwColor-base-black;
    line-height: 26px;
    max-width: 960px;
    margin: 0 auto;
  }

  &__info--primary {
    font-size: 16px;
    font-weight: 300;
  }
}

.error-message {
  color: $cwColor-base-red;
}

/* Prefilled alignment */

.prefilled-values-notice {
  display: inline-block;
  margin: 8px 0 10px 239px;
  font-size: 13px;
  line-height: 16px;
  color: $cwColor-black-xmedium;
  width: 450px;

  @media only screen and (max-width: $tablet){
    width: auto;
  }

  @media only screen and (max-width: $mobile) {
    width: 100%;
    margin: 6px 0 10px;
    text-align: center;
  }

  .textarea ~ & {
    margin-left: 230px;

    @media only screen and (max-width: $mobile) {
      margin: 0 5px;
    }
  }
}

.row-prefilled {
  > * {
    padding-bottom: 0 !important;

    .input-keyboard .input-keyboard__input {
      margin-bottom: 3px;
    }
  }

  .radio-group ~ .prefilled-values-notice {
    margin: 10px 0 10px 230px;

    @media only screen and (max-width: $mobile) {
      margin: 0 5px;
    }
  }
}

.row-location.row-prefilled {
  .prefilled-values-notice {
    margin-top: 7px;
  }
}

.page [class="row-"] > div {
  padding: 10px;
}

.label__help-info {
  display: block;
  line-height: 15px;
  font-size: 12px;
  color: $cwColor-black-xmedium;
}

.row-question {
  @extend .row-group, .row-group--white;
  padding: 10px;

  .state {
    width: 160px;

    @media only screen and (max-width: $mobile) {
      width: 100%;
    }
  }
}

.quote-notes {
  position: relative;

  &__modal {
    padding: 2px;
    background: white;
    color: black;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 200;
    width: 400px;
    height: 210px;
    border-radius: 3px;
    box-shadow: 1px 6px 7px 1px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;

    &-header {
      height: 50px;
      padding: 20px;
      flex: 0 1 auto;
      border-bottom: 1px solid #d2d2d2;
      display: flex;
      align-items: center;
    }

    .close-modal {
      position: absolute;
      right: 20px;
      color: black;
      cursor: pointer;
    }

    &-body {
      margin-top: 5px;
      flex: 1 1 auto;

      &-input {
        width: 100%;
        height: 100px;
        padding: 5px;
        border: none;
        resize: none;
        line-height: 1.5em;
      }
    }

    &-footer {
      height: 50px;
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;

      &-done {
        height: 35px;
        line-height: 1em;
      }
    }
  }
}

.geosuggest__suggests:after {
  content: "";
  display: block;
  background: url("./../images/powered_by_google_on_white.png") right center
    no-repeat white;
  background-size: 120px 15px;
  border-right: solid 1rem white;
  width: 100%;
  height: 3rem;
}

.row-group  {
  position: relative;
  margin: 3px 0;
  padding: 5px 0;
  box-sizing: border-box;
  > div {
    padding: 10px;
  }

  @include qf-clearfix();

  @media only screen and (max-width: $mobile) {
    padding: 0;

    > div {
      padding: 5px 10px;
    }
  }

  &--white {
    background: $cwColor-base-white;
  }
}
