.percentage-graph {
  width: 80px;
  margin: 0 auto;
  text-align: center;

  .doughnut {
    display: block;
    margin: 0 auto;
  }

  @media only screen and (max-width: $mobile) {
    width: 210px;
  }

  &__info {
    position: relative;
  }

  &__value {
    font-size: 19px;
    line-height: 25px;
    font-weight: 100;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 55%;
    transform: translateY(-50%);
    color: $cwColor-base-blue;
    margin: 1px;
  }

  &__label {
    display: inline-block;
    font-size: 12px;
    line-height: 13px;
    color: $cwColor-black-xmedium;
    text-align: center;

    @media only screen and (max-width: $tablet){
      width: auto;
    }

    @media only screen and (max-width: $mobile) {
      width: 100%;
      margin: 6px 0 10px;
      text-align: center;
    }
  }
}
