$mobile-sidebar-height: 270px;
$mobile-footer-padding: 10px;

.footer {
  bottom: 0;
  display: flex;
  z-index: 999;

  @media only screen and (max-width: $mobile) {
    flex-direction: column;
  }

  &__sidebar {
    max-width: $sidebar-width;
    min-width: $sidebar-width;
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem 1rem 2rem;

    &__logo {
      width: 168px;
      height: auto;
    }

    @media only screen and (max-width: $mobile) {
      max-width: none;
      padding: 1rem 9rem 1rem;
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: flex-end;
    border-left: 1px dotted $cwColor-black-light;
    border-right: 1px dotted $cwColor-black-light;
    padding: 10px;
    padding-bottom: 2rem;
    width: calc(100% - #{$sidebar-width + $helpPanel-width});

    @media only screen and (max-width: $tablet){
      width: calc(100% - #{$sidebar-width});
      border-right: none;
    }

    @media only screen and (max-width: $mobile) {
      border: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__help-panel {
    width: $helpPanel-width;
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    font-size: 13px;

    img {
      width: calc(#{$helpPanel-width} - 30px);
    }

    @media only screen and (max-width: $tablet){
      border-left: 1px dotted $cwColor-black-light;
    }

    @media only screen and (max-width: $mobile) {
      width: 100%;
      border-top: 1px dotted $cwColor-black-light;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    height: 44px;
    width: 200px;
    color: #8A8A8A;
    font-family: "Open Sans";
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
  }

  &__text {
    font-size: 13px;
    margin-bottom: 8px;
  }

  &__legal-links {
    display: inline-block;

    &.separator, .footerLink {
      &::after {
        content: "|";
        margin: 0 10px;
        font-size: 13px;
      }
    }

    @media only screen and (max-width: $mobile) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &.separator::after {
        padding-top: 5px;
      }
    }

    .footerLink {
      font-size: 13px;

      &:last-child::after {
        content: "";
        margin: 0;
      }

      @media only screen and (max-width: $mobile) {
        padding-top: 5px;
      }
    }
  }
}
