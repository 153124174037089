.location-create-page {
  h5 {
    display: inline-block;
    margin: 7px 0 3px 0;
  }
  .page-title {
    padding: 0;
  }
  .page-description {
    margin-top: 12px;
  }

  &--block {
    .page-title {
      padding: 0;
      margin-top: 0;
    }
  }

  .street-address .input-group .form-input {
    width: 400px;

    @media only screen and (max-width: $tablet){
      width: 100%;
    }
  }

  .row-location {
    @extend .row-group, .row-group--white;

    &__title {
      font-size: 21px;
      margin: 0 10px;
      padding: 10px 0;
      border-bottom: 1px solid $cwColor-black-mlight;
    }

    &__block {
      position: relative;

      .prefilled-values-notice {
        display: inline-block;
        margin: 8px 0 10px 230px;
        font-size: 13px;
        line-height: 16px;
        color: #8a8a8a;
        width: 450px;

        @media only screen and (max-width: $tablet){
          width: auto;
        }

        @media only screen and (max-width: $mobile) {
          width: 100%;
          margin: 6px 0 10px;
          text-align: center;
        }
      }
    }
  }
  .row-location-sqft-replacement {
    @include qf-clearfix();
    @extend .row-group, .row-group--white;

    .blank {
      width: 240px;
      float: left;
      box-sizing: border-box;

      @media only screen and (max-width: 960px) {
        display: none;
      }
    }
    .sqft,
    .content-replacement {
      float: left;
      box-sizing: border-box;

      @media only screen and (max-width: 960px) {
        @include qf-clearfix();
        width: 100%;
        float: none;
        margin: 10px 10px 10px 240px;
      }
      @media only screen and (max-width: $mobile) {
        margin: 0 auto;
      }
    }
    .sqft {
      width: 180px;

      .form-help-icon {
        margin-right: 108px;
      }
    }
    .content-replacement {
      width: 228px;

      .form-help-icon {
        margin-right: 0;
      }
    }
  }
  .row-location-construction-type {
    @extend .row-group, .row-group--white;

    .form-help-icon--pulled-right {
      margin-left: 890px;
    }
  }
  .cancel {
    margin-right: 15px;
  }

  .bop-inputs {
    .state {
      width: 102px;

      @media only screen and (max-width: $mobile) {
        @include qf-clearfix();
        width: 100%;
        float: none;
        margin: 10px auto;
      }
    }
  }
}

.location {
  padding: 10px 10px 15px;
  background: $cwColor-base-white;

  @media only screen and (max-width: $mobile) {
    padding: 10px;
  }

  &--wc {
    margin-bottom: 20px;
  }

  .content {
    span {
      font-size: 13px;

      &.value {
        font-weight: normal;
        margin-right: 5px;
        color: $cwColor-black-xmedium;
      }
    }
  }

  &__row {
    position: relative;
    width: 100%;
    padding: 0;

    @media only screen and (max-width: $mobile) {
      padding: 0 0 10px 0;
      border-bottom: none;
    }
  }

  &__cell {
    font-size: 14px;
    display: inline-block;
    width: 100%;

    .col {
      padding: 1px 0;
      color: $cwColor-black-dark;

      @media only screen and (max-width: $mobile) {
        display: table;
        width: 100%;
        padding: 5px 0;
        border-bottom: 1px solid $cwColor-black-mlight;
        font-size: 13px;

        &:last-child {
          border-bottom: none;
        }

        span {
          display: table-cell;
          width: 40%;
          padding: 2px 0;
          font-weight: normal;
          word-break: break-word;
          vertical-align: top;

          &+ span {
            vertical-align: bottom;
            text-align: right;
          }
        }
      }

      span + span {
        color: $cwColor-black-xmedium;
      }
    }

    &+ .location-map {
      margin-top: 10px;
    }

    h5 {
      float: left;
      margin: 0;
    }
  }


  &.wc-location {
    padding-bottom: 1px;

    .location__row {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}



.location-list-page {
  .page-title {
    padding: 0;

    &+ .page-description {
      width: 77%;

      @media only screen and (max-width: $tablet){
        width: 100%;
      }
    }
  }

  &--block {
    .page-title {
      padding: 0;
      margin-top: 0;
    }
  }

  .new-location {
    padding: 10px;
    background: $cwColor-base-white;

    @media only screen and (max-width: $tablet){
      padding: 10px;
    }

    &__link {
      &:hover {
        svg {
          fill: $cwColor-black-dark;
        }
      }

      @media only screen and (max-width: $mobile) {
        width: 100%;
        text-align: center;
      }
    }

    &__title {
      font-weight: normal;

      &:before {
        transition: all .2s ease-out;
      }

      svg {
        fill: $cwColor-base-blue;
        margin-right: 4px;
      }
      
      
    }
  }
  .location {
    padding-top: 8px;
  }
  .content {
    .col {
      width: 50%;
      display: inline-block;

      @media only screen and (max-width: $mobile) {
        display: table;
        width: 100%;
        padding: 5px 0;
        border-bottom: 1px solid $cwColor-black-mlight;

        span {
          display: table-cell;
          width: 40%;
          padding: 2px 0;
          font-weight: normal;
          word-break: break-word;
          vertical-align: top;

          &+ span {
            vertical-align: bottom;
            text-align: right;
          }
        }
      }
    }
  }
}

.location-map {
  height: 190px;

  &--content-after {
    margin-bottom: 7px;
  }
}

.requireLocationModal {

  .btn-group {
    display: flex;
    justify-content: center;
    padding-top: 32px;

    @media only screen and (max-width: $mobile) {
      flex-direction: column;
    }
  }

  .new-location {
    padding-right: 24px;
    background: $cwColor-base-white;

    @media only screen and (max-width: $mobile) {
      padding-right: 0;
      margin-bottom: 10px;
    }

    &__link {
      &:hover {
        svg {
          fill: $cwColor-black-dark;
        }
      }

      @media only screen and (max-width: $mobile) {
        width: 100%;
        text-align: center;
      }
    }

    &__title {
      font-weight: normal;

      &:before {
        transition: all .2s ease-out;
      }

      svg {
        fill: $cwColor-base-blue;
        margin-right: 4px;
      }
    }
  }
}
