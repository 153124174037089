.wc-locations-list-page {
  .page-title {
    padding: 0;
    margin-top: 0;
  }

  .row-location {
    @extend .row-group, .row-group--white;
    margin: 0;

    &__title {
      font-size: 21px;
      margin: 0 10px;
      padding: 10px 0;
      border-bottom: 1px solid $cwColor-black-mlight;
    }
  }
}

.wc-location {
  margin-bottom: 0;

  &:not(:first-of-type) {
    padding-top: 10px;
  }

  &__help-text {
    font-size: 14px;
    color: $cwColor-black-xmedium;
  }

  &__wc-container {
    margin-left: 100px;
    margin-bottom: 10px;

    @media only screen and (max-width: $mobile) {
      margin-left: 0;
    }

    h5 {
      margin-bottom: 6px;
    }
  }

  &__wc-class-code {
    padding-bottom: 5px;
    border-bottom: 1px solid $cwColor-black-mlight;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__details {
    padding-right: 20px;
    display: table-cell;
    max-width: 300px;

    @media only screen and (max-width: $mobile) {
      padding-right: 0;
      display: block;
      max-width: none;
    }
  }

  &__details-description,
  &__wc-class-code-description,
  &__wc-class-code-payroll {
    display: table;
    font-size: 14px;
    line-height: 23px;

    @media only screen and (max-width: $mobile) {
      width: 100%;
      font-size: 13px;

      span {
        display: table-cell;
        width: 40%;
        padding: 2px 0;
        font-weight: normal;
        word-break: break-word;
        vertical-align: top;

        & + span {
          vertical-align: bottom;
          text-align: right;
        }
      }
    }

    &__value {
      span {
        vertical-align: bottom;
        text-align: right;
        display: inline;
        width: auto;
      }
    }

    span + span {
      color: $cwColor-black-xmedium;
    }
  }
}
