/**
 * Overrides for application document pdf
 *
 * Each mode-dependent logo needs its .print-view modifier to fix its
 * width and height, as in the pdf, the images get distorted if the
 * width/height ratio is not maintained
 */

.print-view {
  &__logo {
    margin-top: 5px;
    margin-bottom: 20px;

    // Aon Logo
    &--5854562c-21be-4f86-86f2-19b276d0f44d {
      height: 56px;
      img {
        width: 128px;
        height: inherit;
      }
    }

    // HPSO logo
    &--4e8acb7f-110a-4681-83fe-d0e6d7fa7e98 {
      img {
        width: 128px;
        height: 50px;
      }
    }

    // NSO logo
    &--e0381438-116e-4196-876c-fd05e5d62727 {
      img {
        width: 115px;
        height: 47px;
      }
    }

    // Dentists
    &--713b1c8b-e181-4155-b9d8-d96a19b4699e {
      img {
        width: 126px;
        height: 39px;
      }
    }

    // Attorneys
    &--955c0363-1386-539c-df53-20d716cee6f4 {
      height: 56px;
      img {
        width: 128px;
        height: inherit;
      }
    }

    // 7Eleven, Keyfood, Restore, Puroclean
    &--711f7158-20e9-4a3a-8025-e4e75cc32df1,
    &--24e25899-e8e5-4e37-b8cc-8682bd99304f,
    &--af798051-4de8-4f4d-807a-011163cecab2,
    &--e43a1d17-e6cb-4fe0-b465-932f015c8c48{
      height: auto;
      img {
        width: 216px;
        height: inherit;
      }
    }

    // MTY
    &--53d7f0e0-0c42-4d5f-93c1-62cfb9caa732 {
      height: auto;
      img {
        width: 161px;
        height: inherit;
      }
    }

    // Worldpay
    &--29117f95-9c40-4fa7-9da4-fd91be42756a {
      height: 43px;
      img {
        width: 216px;
        height: inherit;
      }
    }

    // Aon France
    &--f656faad-d29f-4030-8ba3-3e61c2188b5d {
      height: 56px;
      img {
        width: 128px;
        height: inherit;
      }
    }
    
     // Aon Spain
     &--42eeb624-8b18-48df-98c6-7ab471ae9180 {
      height: 56px;
      img {
        width: 128px;
        height: inherit;
      }
    }

    // Aon UK
    &--c04bb382-bbdf-4327-81eb-33a71e40d7bc {
      height: 56px;
      img {
        width: 128px;
        height: inherit;
      }
    }
  }
}
