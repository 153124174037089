.uw-page {
  &__multiselect-label {
    padding: 0 10px;
    font-size: 0.875rem;
  }

  .oc-option__next-icon {
    display: none;
  }

  ul, p {
    padding: 0;
    margin: 0;
  }
}
