.employees-page {

  .row-employees {
    @extend .row-group, .row-group--white;
    
    &.row-total--disabled {
      .input-group {
        position: relative;
        height: 38px;
        color: $cwColor-text-disabled;
        &::before {
          font-size: 15px;
          position: absolute;
          left: 83px;
          top: 9px;
          content: '%';
          
          @media only screen and (max-width: $mobile) {
            position: absolute;
            left: auto;
            right: 8px;
            top: 10px;
            pointer-events: none;
          }
        }
      }
    }
  }
  
  @include dynamic-tooltip($larger-box-tooltip-position: 690px) ;
}
