.overlay-contact-agent {
  @media only screen and (max-width: $mobile) {
    position: static;
  }

  .modal {
    padding: 16px 16px 32px;

    @media only screen and (max-width: $mobile) {
      top: 0;
      left: 0;
      transform: none;
      min-width: 0;
      width: 100vw;
      height: 100vh;
    }
  }

  .popup-contact-agent {
    height: 100%;
    padding: 0;

    .btn-close {
      top: 0;
      right: 0;
      width: 38px;
      height: 38px;
    }

    .popup__content {
      padding-top: 48px;
      height: 100%;
      margin: 0 64px;

      @media only screen and (max-width: $mobile) {
        margin: 0;
      }
    }
  }
}

.contact-agent-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media only screen and (max-width: $mobile) {
    justify-content: space-between;
    height: 100%;
  }

  p {
    margin: 0;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    @media only screen and (max-width: $mobile) {
      gap: 16px;
    }
  }

  &__photo {
    width: 80px;
    height: 80px;

    @media only screen and (max-width: $mobile) {
      width: 60px;
      height: 60px;
    }
  }

  &__title {
    font-weight: 400;
    margin: 0;
  }

  &__content {
    max-width: 416px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (max-width: $mobile) {
      gap: 16px;
    }
  }

  &__subtitle,
  &__line {
    font-size: 14px;
    line-height: 20px;
    max-width: 384px;
  }

  &__line {
    display: flex;
    gap: 4px;
  }

  &__buttons-block {
    align-self: center;

    @media only screen and (max-width: $mobile) {
      width: 100%;
    }
  }
}
