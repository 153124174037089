.tria-disclosure {
  &__logo-container {
    @include qf-clearfix();
    position: relative;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .logo {
      position: relative;
      top: 5px;
      width: 166px;
      margin-left: 20px;
    }
  }

  &__content-container {
    margin: 15px;
  }

  &__close-link {
    color: $cwColor-base-blue;
    float: right;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  &__paragraph {
    margin: 15px 0;
    text-align: justify;
  }
}
