.renewals-referral-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $cwColor-white;
  text-align: center;
  padding: 32px 0;

  .confirmation-icon {
    padding: 16px;
    background-color: #f0f0f0;
    border-radius: 50%;

    svg {
      fill: #0083a9;
    }
  }

  .page-title {
    padding: 0;
    font-size: 30px;
    font-weight: lighter;
  }

  .page-subtitle {
    @media only screen and (max-width: $mobile) {
      word-break: break-word;
    }

    span {
      color: #438bd7;
    }
    &--pdf-link {
      padding-top: 10px;
    }
  }
}
