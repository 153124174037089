.df-summary {
  .block-title {
    margin-bottom: 0;
    @media only screen and (max-width: $tablet){
      text-align: center;
      margin-top: 20px;
      display: block;
    }

    &+ .page-title {
      margin-top: 0;
      padding-top: 0;
    }
  }
}