.industry-page {

  &__name {
    margin-bottom: 0;

    @media only screen and (max-width: $tablet){
      text-align: center;
    }
  }

  &__title {
    margin-top: 0;
    padding-top: 0;
  }

  &__label {
    padding-top: 10px !important;
    padding-left: 10px !important;

    @media only screen and (max-width: $mobile) {
      text-align: center;
      padding-left: 0px !important;
    }
  }

  &__description-of-operations-container {
    padding: 10px 40px 0px 0px;
    width: 100%;

    @media only screen and (max-width: $tablet){
      padding-right: 0px;
    }
  }

  &__description-of-operations {
    max-width: 500px;
    min-height: 90px;
  }

  &__description-of-operations-tooltip-icon {
    top: 20px !important;
  }

  .page-title {
    padding: 0 5px 0 0;
  }

  .page-description {
    @media only screen and (max-width: $mobile) {
      display: none;
    }
  }

  .help-info {
    @media only screen and (max-width: $mobile) {
      text-align: center;
      padding-left: 0;
    }
  }
}
